@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body { 
    background-color: rgb(57, 57, 57); 
      display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.App {
  /* Let's leave this bg for now, might change later
  Not really feeling it, let's leave like this for now. Again :)
  */
  background-color: #ff4d00c6;
  height: 75vh;
  width: 20rem;
  border-radius: 30px;
  padding: 3rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#logo img {
  width: 25%;
}

#logo h2 {
  color: white;
  margin-bottom: 0;
}

#body {
  display: flex;
  flex-direction: column;
  color: white;
}

#body input {
  background: none;
  border: none;
  border-bottom: 2px solid white;
  outline: none;
  color: white;
  font-size: 1.2rem;
  padding: 0.4rem 0.1rem;
}

#body input::placeholder {
  color: rgba(255, 255, 255, 0.8); 
}

#body span {
  font-size: 0.8rem;
}

.App button {
  background-color: white;
  border: none;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: bold;
  color: #ff4d00;
  height: 2.7rem;
  box-shadow: 0px 5px 8px 3px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.App button:hover {
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.08);
}

.App .btn-disabled {
  opacity: 0.5;
  cursor: wait;
  box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.03);
}

@media only screen and (max-width: 600px) {
  .App {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }

  #logo img {
    width: 20%;
  }  
}